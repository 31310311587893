import {EventBus} from "../../events/EventBus";
import {RefreshSwipeComponentEvent} from "../../events/Events";

const swipeContainerSelector: string = 'js-wl-swipe-container';
const swiperContainerDataScrollAmount: string = 'data-scroll-amount';
const elementsSelector: string = 'js-wl-swipe-elements';
const leftArrowSelector: string = 'js-wl-swipe-left-arrow';
const rightArrowSelector: string = 'js-wl-swipe-right-arrow';
const hiddenClass: string = 'hidden';

export class SwipeComponent {
    private readonly swipeContainer: Element;
    private readonly elementsContainer: Element;
    private readonly leftArrow: Element;
    private readonly rightArrow: Element;

    constructor(container: Element, scrollAmount: number) {
        this.swipeContainer = container;
        this.elementsContainer = this.swipeContainer.querySelector(`.${elementsSelector}`)!!;
        this.leftArrow = this.swipeContainer.querySelector(`.${leftArrowSelector}`)!!;
        this.rightArrow = this.swipeContainer.querySelector(`.${rightArrowSelector}`)!!;
        this.initializeArrows(scrollAmount);
        EventBus.getInstance().subscribe(RefreshSwipeComponentEvent.TYPE, () => {
            this.refreshArrows();
        });
    }

    initializeArrows(scrollAmount: number) {
        const scrollLeft = this.elementsContainer.scrollLeft;
        if (scrollLeft === 0) {
            this.leftArrow.classList.add(hiddenClass);
        } else {
            this.leftArrow.classList.remove(hiddenClass);
        }
        if (0 === this.elementsContainer.scrollWidth - this.elementsContainer.clientWidth) {
            this.rightArrow.classList.add(hiddenClass);
        } else {
            this.rightArrow.classList.remove(hiddenClass);
        }
        this.setButtonBehaviour(this.leftArrow, -scrollAmount);
        this.setButtonBehaviour(this.rightArrow, scrollAmount);
    }

    setButtonBehaviour(arrow: Element, scrollAmount: number) {
        arrow.addEventListener('click', (event) => {
            event.preventDefault();
            event.stopImmediatePropagation();
            this.elementsContainer.scrollBy({left: scrollAmount, behavior: 'smooth'});
        });
        this.elementsContainer.addEventListener('scrollend', () => {
            this.refreshArrows();
        });
    }

    refreshArrows() {
        const scrollLeft = this.elementsContainer.scrollLeft;
        const scrollWidth = this.elementsContainer.scrollWidth;
        const clientWidth = this.elementsContainer.clientWidth;
        const maxScrollLeft = scrollWidth - clientWidth;
        const isScrolling = scrollLeft >= 0 && scrollLeft < maxScrollLeft;

        this.leftArrow.classList.toggle(hiddenClass, scrollLeft === 0);
        this.rightArrow.classList.toggle(hiddenClass, !isScrolling);
    }

    static init = (container: Element, scrollAmount: number) => {
        return new SwipeComponent(container, scrollAmount);
    };
    static getSwiperContainerSelector = () => swipeContainerSelector;
    static getSwiperScrollAmountSelector = () => swiperContainerDataScrollAmount;
}

document.querySelectorAll(`.${swipeContainerSelector}`).forEach(container => {
    const scrollAmount: number = parseInt(container.getAttribute(swiperContainerDataScrollAmount) || '0');
    SwipeComponent.init(container, scrollAmount);
});
